var $ = jQuery;
$(document).ready(function(){
	if($('.header-slider')){
		$('.header-slider').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 300,
			nextArrow: $('.header-slider__nav-item.next'),
			prevArrow: $('.header-slider__nav-item.prev')
		});
	}

	if($('.gallery-slider')){
		$('.gallery-slider').slick({
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 300,
			nextArrow: $('.gallery-slider__nav-item.next'),
			prevArrow: $('.gallery-slider__nav-item.prev'),
		});
	
		$('.gallery-slider').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide){
			//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
			var i = (currentSlide ? currentSlide : 0) + 1;
			$('.gallery-slider__index').text(i + '/' + slick.slideCount);
		});
	
		var galleryItem = document.querySelectorAll('.gallery-item');
		galleryItem.forEach(item => {
			item.addEventListener('click', ()=>{
				$('.gallery-slider').slick('slickGoTo', (item.dataset.index - 1))
				openModal();
			})
		});
	}

	if($('.beforeAfter-container')){
		$('.beforeAfter-container .right').click(function(){
			$(this).siblings('.modal').toggleClass('hidden')
		})
		$('.beforeAfter-container .modal-exit').click(function(){
			$(this).parent().toggleClass('hidden')
		})
	}

});

$(document).ready(function() {

	//scroll header fun

	let lastScrollTop = 0;
	let buffer = 50;
	const header = document.querySelector('.header-container');

	const scrollingUp = () => {
		var st = window.pageYOffset || document.scrollTop;
		let goingUp;
		if (st > lastScrollTop) {
			goingUp = false;
		} else {
			if (st < buffer || st == undefined) {
				goingUp = false;
			} else {
				goingUp = true;
			}
		}
		// For Mobile or negative scrolling
		lastScrollTop = st <= 0 ? 0 : st;

		return goingUp;
	};


	window.addEventListener(
        'scroll',
        () => {
            toggleLargeScrollState();
            scrollDirection();
        }
	);
	
	function toggleLargeScrollState() {
        if (window.scrollY > 92) {
            !header.classList.contains('scrolled') &&
                header.classList.add('scrolled');
        } else {
            header.classList.contains('scrolled') &&
                header.classList.remove('scrolled');
        }
	}
	
	function scrollDirection() {
        let direction = scrollingUp();

        if (direction) {
            header.classList.contains('scrolled') && header.classList.remove('scrolled');
        } else {
            !header.classList.contains('scrolled') && header.classList.remove('scrolled');
        }
    }

		

	$(".menuIconContainer").click(function() {
		toggleMenu()

		if ($(this).hasClass("active")) {
			$(this)
				.find("i.fa-bars")
				.removeClass("fa-bars")
				.addClass("fa-times")
		} else {
			$(this)
				.find("i.fa-times")
				.removeClass("fa-times")
				.addClass("fa-bars")
		}

		$(".image-row .filter").click(function() {
			alert()
		})
	})

	$(".special-offer").click(function() {
		$(this).toggleClass("active")
		$(".prices-copy-container > .right > .background").toggleClass("active")
		$(".prices-copy-container > .right > .filter").toggleClass("active")

		if ($(this).hasClass("active")) {
			if ($(window).width() < 600) {
				setTimeout(function() {
					$(".prices-copy-container > .left > .hidden").toggleClass("active")
				}, 40)
			} else {
				$(".prices-copy-container > .left > .hidden").toggleClass("active")
			}
		} else {
			$(".prices-copy-container > .left > .hidden").toggleClass("active")
		}

		$(".prices-copy-container > .left > .not-hidden").toggleClass("active")
	})

	$(".navHeader .iconContainer").click(function() {
		toggleMenu()
	})

	let headerScrolled = false

	//scroll reveal
	var slideUp = {
		distance: "40%",
		origin: "bottom",
		opacity: 0,
		duration: 800
	}

	if ($(window).width() > 600) {
		ScrollReveal().reveal(".slide-up", slideUp)
	}

	//forms
	$(".submit-button > span").click(function(e) {
		submitForm("form.contact-form", ".modal-error-msg", ".modal-success-msg", e)
	})

	window.onresize = function(event) {
		//activate before after mobile effect
		if (window.innerWidth < 600) {
			//adds active class to 'before' image
			beforeAfterOnMobile()
			//toggles active class for left and right based on click
			clickToShowBeforeAfter()
		} else {
			$(".mobile-title").css("display", "none")
		}
	}

	//activate before after mobile effect
	if (window.innerWidth < 600) {
		//adds active class to 'before' image
		beforeAfterOnMobile()
		//toggles active class for left and right based on click
		clickToShowBeforeAfter()
	}
})

function goToPage(blob) {
	window.location(blob)
}

function submitForm(formClass, errorClass, successClass, e) {
	loading("show")

	e.preventDefault()
	//vars
	var FORMdata = $(formClass).serialize()
	var url = "../php/submit_form.php"

	$.ajax({
		type: "POST",
		url: url,
		dataType: "json",
		data: FORMdata
	})
		.done(function(response) {
			if (response.success) {
				$(errorClass).html("")
				formSuccess(successClass)
			} else {
				loading("hide")
				$(errorClass).html(response.error)
				$(errorClass).addClass("submission-fail")
			}
		})
		.fail(function(response) {
			loading("hide")
			$(errorClass).html(response.error)
			$(errorClass).addClass("submission-fail")
		})
}

function formSuccess(successClass) {
	loading("hide")

	$("input").val("")
	$("textarea").val("")
	$(successClass).html("thank you, we will be in touch shortly.")
	$(successClass).addClass("submission-success")
	setTimeout(function() {
		location.reload()
	}, 3000)
}

function loading(type) {
	switch (type) {
		case "show":
			$(".loading-row").css("display", "flex")
			$('input[name="submitForm"]').css("display", "none")
			break
		case "hide":
			$(".loading-row").css("display", "none")
			$('input[name="submitForm"]').css("display", "block")
			break
		default:
			$(".loading-row").css("display", "none")
			$('input[name="submitForm"]').css("display", "block")
			break
	}
}



function toggleMenu() {
	$(".navMenu").toggleClass("active")
	$(".menuIconContainer").toggleClass("active")
	$(".navMenu").toggleClass("scrolled")
	$(".navContainer").toggleClass("active")
}


//Gallery Modal
// Open the Modal
function openModal() {
	document.getElementById("GalleryModal").style.display = "block"
	document.getElementById("header").style.position = "initial"
}

// Close the Modal
function closeModal() {
	document.getElementById("GalleryModal").style.display = "none"
	document.getElementById("header").style.position = "fixed"
}


function beforeAfterOnMobile() {
	$(".beforeAfter-container .image-row .left").addClass("active")
	$(".mobile-title").css("display", "block")
}

function clickToShowBeforeAfter() {
	let beforeImage = $(".beforeAfter-container .image-row .left")
	let afterImage = $(".beforeAfter-container .image-row .right")

	$(beforeImage).click(function() {
		$(this).toggleClass("active")
		$(this)
			.next(".right")
			.toggleClass("active")
	})

	$(afterImage).click(function() {
		$(this).toggleClass("active")
		$(this)
			.prev(".left")
			.toggleClass("active")
	})
}
